<template>
  <Vue3Lottie
    v-bind="$attrs"
    ref="lottieRef"
  />
</template>

<script setup>
import { Vue3Lottie } from 'vue3-lottie';

// Refs
const lottieRef = ref(null);

defineExpose({
  lottieRef,
});
</script>
